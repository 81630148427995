import React, { useState, useEffect } from "react";
import "../../styles/global.css";
import MyHome from "../HomePage/MyHome";
import MyExpert from "../HomePage/MyExpert";
import MyCommunity from "../HomePage/MyCommunity";
import ActiveCases from "../HomePage/ActiveCases";
import MyPortfolio from "../HomePage/MyPortfolio"; // Import My Portfolio
import apiClientWithAuth from "../../axiosClient";

const UserHomePage = () => {
  const [activeTab, setActiveTab] = useState(null); // No default tab initially
  const [userRole, setUserRole] = useState(null); // To store the user's role
  const [userName, setUserName] = useState(""); // To display the user's name

  // Fetch user info on load
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await apiClientWithAuth.get("users/login"); // API call to get user info
        const { name, roleType } = response.data; // Extract name and role
        setUserRole(roleType); // Set user role (e.g., "HO" or "HE")
        setUserName(name); // Set user name

        // Set default tab based on role
        if (roleType === "HE") {
          setActiveTab("cases"); // Default to My Portfolio for HE
        } else if (roleType === "HO") {
          setActiveTab("home"); // Default to My Home for HO
        }
      } catch (error) {
        console.error("Error fetching user info:", error.message);
      }
    };

    fetchUserInfo();
  }, []);

  // Render loading state while fetching user info
  if (!userRole || !activeTab) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-stone-300 w-screen h-screen">
      {/* Page Header */}
      <h1 className="text-center text-4xl font-bold text-blue-900 py-6">
        Welcome {userName || "User"}
      </h1>

      {/* Tabs Navigation */}
      <div className="flex justify-center border-b-2 border-gray-300 mb-4">
        {/* Role-Specific Tabs for HE */}
        {userRole === "HE" && (
          <>
            <button
              className={`py-2 px-6 ${
                activeTab === "portfolio"
                  ? "border-b-4 border-blue-600 text-blue-600 font-bold"
                  : "text-gray-600 hover:text-blue-600"
              }`}
              onClick={() => setActiveTab("portfolio")}
            >
              My Portfolio
            </button>
            <button
              className={`py-2 px-6 ${
                activeTab === "cases"
                  ? "border-b-4 border-blue-600 text-blue-600 font-bold"
                  : "text-gray-600 hover:text-blue-600"
              }`}
              onClick={() => setActiveTab("cases")}
            >
              Active Cases
            </button>
            <button
              className={`py-2 px-6 ${
                activeTab === "community"
                  ? "border-b-4 border-blue-600 text-blue-600 font-bold"
                  : "text-gray-600 hover:text-blue-600"
              }`}
              onClick={() => setActiveTab("community")}
            >
              My Community
            </button>
          </>
        )}

        {/* Tabs for HO */}
        {userRole === "HO" && (
          <>
            <button
              className={`py-2 px-6 ${
                activeTab === "home"
                  ? "border-b-4 border-blue-600 text-blue-600 font-bold"
                  : "text-gray-600 hover:text-blue-600"
              }`}
              onClick={() => setActiveTab("home")}
            >
              My Home
            </button>
            <button
              className={`py-2 px-6 ${
                activeTab === "expert"
                  ? "border-b-4 border-blue-600 text-blue-600 font-bold"
                  : "text-gray-600 hover:text-blue-600"
              }`}
              onClick={() => setActiveTab("expert")}
            >
              My Expert
            </button>
            <button
              className={`py-2 px-6 ${
                activeTab === "community"
                  ? "border-b-4 border-blue-600 text-blue-600 font-bold"
                  : "text-gray-600 hover:text-blue-600"
              }`}
              onClick={() => setActiveTab("community")}
            >
              My Community
            </button>
          </>
        )}
      </div>

      {/* Content for Each Tab */}
      <div className="px-6 py-4">
        {userRole === "HO" && activeTab === "home" && <MyHome />}
        {userRole === "HO" && activeTab === "expert" && <MyExpert />}
        {activeTab === "community" && <MyCommunity />}
        {userRole === "HE" && activeTab === "cases" && <ActiveCases />}
        {userRole === "HE" && activeTab === "portfolio" && <MyPortfolio />}
      </div>
    </div>
  );
};

export default UserHomePage;