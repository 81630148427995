import React from "react";

const MyCommunity = () => {
  return (
    <div className="text-center">
      <h2 className="text-3xl font-bold mb-4 bg-gradient-to-r from-blue-500 to-purple-500 text-transparent bg-clip-text">
        Welcome to the Community!
      </h2>
      <p className="text-lg text-gray-700 mb-6">
        We are hard at work building a custom community tailored for homeowners, service providers, and home experts.
      </p>
      <p className="text-md text-gray-500 italic mb-6">
        Stay tuned for exciting updates and features to connect, collaborate, and learn together.
      </p>
      <p className="text-lg text-gray-700 font-semibold mb-6">Coming Soon</p>
      <div className="mt-8">
        <button
          onClick={() => (window.location.href = "/")}
          className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg shadow-md"
        >
          Back to Home
        </button>
      </div>
    </div>
  );
};

export default MyCommunity;