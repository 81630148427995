import React from "react";

const MyHome = () => {
  return (
    <div className="px-4 py-6">
      {/* Header */}
      <h1 className="bg-gradient-to-r from-blue-500 to-purple-500 text-transparent bg-clip-text text-center font-bold text-5xl mb-6">
        Your Home Details
      </h1>

      {/* No Home Found Message */}
      <p className="text-center text-gray-700 text-2xl font-medium mb-4">
        No Home Found! Please add now.
      </p>

      {/* Demo Message */}
      <p className="text-center text-gray-600 text-lg">
        It’s easy, safe, and convenient. <span className="text-blue-600 font-bold cursor-pointer hover:underline">See a demo!</span>
      </p>
    </div>
  );
};

export default MyHome;