import axios from "axios";
import { getAuth } from "firebase/auth";

// Create an Axios instance
// local - production control
const apiClientWithAuth = axios.create({
  baseURL: "https://home-owners.tech/api/",
  //baseURL: "http://localhost:2101/api/",
  timeout: 10000, // Optional
});

// Add a request interceptor to include the Firebase token
apiClientWithAuth.interceptors.request.use(
    async (config) => {
      const auth = getAuth();
      const user = auth.currentUser;
  
      try {
        if (user) {
          const token = await user.getIdToken();
          config.headers.Authorization = `Bearer ${token}`;
        }
      } catch (error) {
        console.error("Error fetching Firebase token:", error.message);
      }
  
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

export default apiClientWithAuth;