import React, { useState } from "react";
import "../../styles/global.css";
import MyHome from "../HomePage/MyHome";
import MyExpert from "../HomePage/MyExpert";
import MyCommunity from "../HomePage/MyCommunity"; // Import the new component

const UserHomePage = () => {
  const [activeTab, setActiveTab] = useState("home"); // Default tab

  return (
    <div className="bg-stone-300 w-screen h-screen">
      {/* Page Header */}
      <h1 className="text-center text-4xl font-bold text-blue-900 py-6">
        Welcome to Your Home Page
      </h1>

      {/* Tabs Navigation */}
      <div className="flex justify-center border-b-2 border-gray-300 mb-4">
        <button
          className={`py-2 px-6 ${
            activeTab === "home"
              ? "border-b-4 border-blue-600 text-blue-600 font-bold"
              : "text-gray-600 hover:text-blue-600"
          }`}
          onClick={() => setActiveTab("home")}
        >
          My Home
        </button>
        <button
          className={`py-2 px-6 ${
            activeTab === "expert"
              ? "border-b-4 border-blue-600 text-blue-600 font-bold"
              : "text-gray-600 hover:text-blue-600"
          }`}
          onClick={() => setActiveTab("expert")}
        >
          My Expert
        </button>
        <button
          className={`py-2 px-6 ${
            activeTab === "community"
              ? "border-b-4 border-blue-600 text-blue-600 font-bold"
              : "text-gray-600 hover:text-blue-600"
          }`}
          onClick={() => setActiveTab("community")}
        >
          My Community
        </button>
      </div>

      {/* Content for Each Tab */}
      <div className="px-6 py-4">
        {activeTab === "home" && <MyHome />}
        {activeTab === "expert" && <MyExpert />}
        {activeTab === "community" && <MyCommunity />}
      </div>
    </div>
  );
};

export default UserHomePage;