import React, { useState, useEffect } from "react";
import { getAuth, updateProfile } from "firebase/auth";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase";

const Profile = () => {
  const auth = getAuth();
  const user = auth.currentUser;

  const [formData, setFormData] = useState({
    displayName: user?.displayName || "",
    phoneNumber: user?.phoneNumber || "",
    email: user?.email || "",
    photoURL: user?.photoURL || null,
  });

  const [photoFile, setPhotoFile] = useState(null); // For photo upload
  const [statusMessage, setStatusMessage] = useState("");

  // Populate user information on component load
  useEffect(() => {
    if (user) {
      setFormData({
        displayName: user.displayName || "",
        phoneNumber: user.phoneNumber || "",
        email: user.email || "",
        photoURL: user.photoURL || null,
      });
    }
  }, [user]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Handle file input
  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setPhotoFile(e.target.files[0]);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatusMessage("Updating profile...");

    try {
      const updates = {};

      // If there's a photo file, upload it to Firebase Storage
      if (photoFile) {
        const storageRef = ref(storage, `profile_photos/${user.uid}`);
        const snapshot = await uploadBytes(storageRef, photoFile);
        const photoURL = await getDownloadURL(snapshot.ref);
        updates.photoURL = photoURL;
      }

      // Update user profile information
      if (formData.displayName) updates.displayName = formData.displayName;
      if (formData.phoneNumber) updates.phoneNumber = formData.phoneNumber;

      await updateProfile(user, updates);
      setStatusMessage("Profile updated successfully!");

      // Update form data to reflect changes
      setFormData((prevData) => ({
        ...prevData,
        photoURL: updates.photoURL || prevData.photoURL,
      }));
    } catch (error) {
      console.error("Error updating profile:", error);
      setStatusMessage("Failed to update profile. Please try again.");
    }
  };

  return (
    <div className="max-w-2xl mx-auto mt-8 p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-2xl font-bold mb-6 text-center">Edit Profile</h1>

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Display Name */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Name
          </label>
          <input
            type="text"
            name="displayName"
            value={formData.displayName}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm"
            placeholder="Enter your name"
          />
        </div>

        {/* Phone Number */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Phone Number
          </label>
          <input
            type="tel"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm"
            placeholder="Enter your phone number"
          />
        </div>

        {/* Email */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Email (read-only)
          </label>
          <input
            type="email"
            value={formData.email}
            readOnly
            className="mt-1 block w-full p-2 border border-gray-300 rounded-lg bg-gray-100"
          />
        </div>

        {/* Profile Photo */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Profile Photo
          </label>
          {formData.photoURL && (
            <img
              src={formData.photoURL}
              alt="Profile"
              className="w-16 h-16 rounded-full mt-2"
            />
          )}
          <input
            type="file"
            onChange={handleFileChange}
            className="mt-2 block w-full"
          />
        </div>

        {/* Submit Button */}
        <div>
          <button
            type="submit"
            className="w-full py-2 px-4 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 transition duration-200"
          >
            Update Profile
          </button>
        </div>
      </form>

      {/* Status Message */}
      {statusMessage && (
        <p className="mt-4 text-center text-sm text-gray-600">
          {statusMessage}
        </p>
      )}
    </div>
  );
};

export default Profile;