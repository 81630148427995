import React, { useState } from "react";
import apiClientWithAuth from "../../axiosClient";

const MyExpert = () => {
  const [inputValue, setInputValue] = useState("");
  const [chatLog, setChatLog] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!inputValue.trim()) return;

    setChatLog((prevChatLog) => [
      ...prevChatLog,
      { type: "user", message: inputValue },
    ]);

    setInputValue("");
    setIsLoading(true);

    try {
      const response = await apiClientWithAuth.get("users/login");
      if (response.status !== 200) throw new Error("Network response was not ok");
      const data = response.data;
      setChatLog((prevChatLog) => [
        ...prevChatLog,
        { type: "system", message: data },
      ]);
    } catch (error) {
      setChatLog((prevChatLog) => [
        ...prevChatLog,
        { type: "system", message: `Error: Unable to fetch message. (${error.message})` },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="px-4 py-6">
      {/* Header */}
      <h1 className="bg-gradient-to-r from-blue-500 to-purple-500 text-transparent bg-clip-text text-center font-bold text-5xl mb-6">
      “Got a question or problem? Your home expert is here to help!”
      </h1>

      {/* Chat Log */}
      <div className="flex flex-col space-y-4 mb-4">
        {chatLog.map((message, index) => (
          <div
            key={index}
            className={`flex ${
              message.type === "user" ? "justify-end" : "justify-start"
            }`}
          >
            <div
              className={`${
                message.type === "user" ? "bg-purple-500" : "bg-gray-800"
              } rounded-lg p-4 text-white max-w-sm`}
            >
              {message.message}
            </div>
          </div>
        ))}
        {isLoading && (
          <div className="flex justify-center">
            <div className="text-gray-600">Loading...</div>
          </div>
        )}
      </div>

      {/* Input Form */}
      <form onSubmit={handleSubmit} className="flex items-center space-x-2">
        <input
          type="text"
          className="flex-grow px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Type your message..."
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
        <button
          type="submit"
          className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300"
        >
          Send
        </button>
      </form>
    </div>
  );
};

export default MyExpert;