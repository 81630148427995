import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";

import UserHomePage from "./components/auth/UserHomePage";
import Profile from "./components/auth/Profile";
import SignIn from "./components/auth/Signin";
import Product from "./components/Product";
import About from "./components/About";

function App() {
  const [isLoading, setIsLoading] = useState(true); // Show a loader while checking auth state
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userEmail, setUserEmail] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
        setUserEmail(user.email || "Guest");
      } else {
        setIsLoggedIn(false);
        setUserEmail(null);
      }
      setIsLoading(false); // Stop loading once auth state is checked
    });

    return () => unsubscribe(); // Clean up the listener on unmount
  }, []);

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        setIsLoggedIn(false);
        setUserEmail(null);
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };

  if (isLoading) {
    // Show a loading state while waiting for Firebase to resolve
    return (
      <div className="min-h-screen flex justify-center items-center">
        Loading...
      </div>
    );
  }

  return (
    <Router>
      <div className="min-h-screen bg-stone-300 flex flex-col">
        {/* Header */}
        <div className="bg-gradient-to-r from-blue-500 to-purple-600 p-4 rounded-lg shadow-md">
          <div className="max-w-7xl mx-auto flex items-center justify-between">
            {/* Title */}
            <div className="flex items-center space-x-4">
              <Link
                to="/"
                className="text-white text-3xl font-extrabold tracking-wide"
              >
                HomePro
              </Link>
              <nav className="flex space-x-4">
                <Link to="/product" className="text-white text-lg hover:underline">
                  Product
                </Link>
                <Link to="/about" className="text-white text-lg hover:underline">
                  About
                </Link>
              </nav>
            </div>

            {/* Profile and Sign Out */}
            {isLoggedIn && (
              <div className="flex items-center space-x-4">
                <p className="text-white text-lg">{`Hi, ${userEmail}`}</p>
                <Link
                  to="/profile"
                  className="text-white text-lg bg-blue-700 hover:bg-blue-800 px-4 py-2 rounded-lg shadow-md transition duration-200"
                >
                  Profile
                </Link>
                <button
                  onClick={handleLogout}
                  className="text-white text-lg bg-blue-700 hover:bg-blue-800 px-4 py-2 rounded-lg shadow-md transition duration-200"
                >
                  Sign Out
                </button>
              </div>
            )}
          </div>
        </div>

        {/* Main Content */}
        <Routes>
          <Route
            path="/signin"
            element={
              isLoggedIn ? (
                <UserHomePage onLogout={handleLogout} />
              ) : (
                <SignIn onLogin={() => setIsLoggedIn(true)} />
              )
            }
          />
          <Route
            path="/"
            element={
              isLoggedIn ? (
                <UserHomePage onLogout={handleLogout} />
              ) : (
                <SignIn onLogin={() => setIsLoggedIn(true)} />
              )
            }
          />
          <Route path="/product" element={<Product />} />
          <Route path="/about" element={<About />} />
          <Route path="/profile" element={<Profile />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;