import { initializeApp } from "firebase/app";
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth";
import { getStorage } from "firebase/storage";

// Firebase configuration object
const firebaseConfig = {
  apiKey: "AIzaSyBGDRmTdzTmjN4jaCzZ9uREikMm-9SaisQ",
  authDomain: "home-owners-tech.firebaseapp.com",
  projectId: "home-owners-tech",
  storageBucket: "home-owners-tech.appspot.com",
  messagingSenderId: "163250332793",
  appId: "1:163250332793:web:195497435b39f4cb92e0a8",
};

// Initialize Firebase App
const app = initializeApp(firebaseConfig);

// Initialize Firebase Auth
export const auth = getAuth(app);

// Set auth persistence
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Persistence mode set to local.");
  })
  .catch((error) => {
    console.error("Error setting persistence mode:", error);
  });

// Initialize Firebase Storage
export const storage = getStorage(app);