import React from "react";

const ActiveCases = () => {
  return (
    <div className="px-4 py-6">
      {/* Header */}
      <h1 className="bg-gradient-to-r from-green-500 to-blue-500 text-transparent bg-clip-text text-center font-bold text-5xl mb-6">
        Your Active Cases
      </h1>

      {/* No Active Cases Found Message */}
      <p className="text-center text-gray-700 text-2xl font-medium mb-4">
        No Active Cases Found! You’re all caught up.
      </p>

      {/* Suggestion Message */}
      <p className="text-center text-gray-600 text-lg">
        Stay tuned for new tasks, or explore the{" "}
        <span className="text-green-600 font-bold cursor-pointer hover:underline">
          community!
        </span>
      </p>
    </div>
  );
};

export default ActiveCases;