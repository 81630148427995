import React from "react";

const MyPortfolio = () => {
  return (
    <div className="px-4 py-6">
      {/* Header */}
      <h1 className="bg-gradient-to-r from-purple-500 to-pink-500 text-transparent bg-clip-text text-center font-bold text-5xl mb-6">
        My Portfolio
      </h1>

      {/* Placeholder Message */}
      <p className="text-center text-gray-700 text-2xl font-medium mb-4">
        Showcase your expertise and completed projects here.
      </p>

      {/* CTA */}
      <p className="text-center text-gray-600 text-lg">
        Add your achievements, certificates, and more to impress homeowners.
      </p>
    </div>
  );
};

export default MyPortfolio;